import { useEffect } from "react";

import { getTrackingIdentifier, registerLogRocketInstrumentation } from "@workleap-tracking/logrocket";

import { useUserContext } from "@contexts/user/UserContext";

import { trackLogRocket } from "@core/tracking/track";

import useIsFeatureEnabled from "./useIsFeatureEnabled";

export const useLogRocket = () => {
    const { context } = useUserContext();

    const isLogRocketEnabled = useIsFeatureEnabled(feature => feature.useLogRocket);

    const appId = window.env.LOGROCKET_APP_ID;

    const sendSessionUrlToTrackingService = async(sessionUrl: string) => {
        trackLogRocket(sessionUrl);
    };

    useEffect(() => {
        if (!context) {
            return;
        }

        const { organisationId, organisationName, userId, isCollaborator, isCompanyManager, isNetworkAdmin, isNetworkCreator, isReportingManager, isTeamManager, isMigratedToWorkleap, planCode } = context;

        const trackingIdentifier = getTrackingIdentifier({ generateCookieOnDefault: true, pregeneratedTrackingIdentifier: userId });

        if (appId && isLogRocketEnabled && trackingIdentifier) {
            const user = {
                userId,
                organizationId: organisationId,
                organizationName: organisationName,
                isMigratedToWorkleap,
                isAdmin: isNetworkAdmin,
                isOrganizationCreator: isNetworkCreator,
                isReportingManager,
                isTeamManager,
                isExecutive: { wov: isCompanyManager },
                isCollaborator: { wov: isCollaborator },
                planCode: { wov: planCode }
            };

            registerLogRocketInstrumentation({
                appId,
                trackingIdentifier,
                identifyOptions: user,
                onSessionUrlInitialized: async(sessionUrl: string) => sendSessionUrlToTrackingService(sessionUrl)
            });
        }
    }, [appId, isLogRocketEnabled, context]);
};