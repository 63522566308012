import { trackAsync } from "@api/TrackingApiClient";
import bearerFactory from "@core/bearer/bearerFactory";
import type ActivityType from "@core/enums/ActivityType";
import type TipType from "@core/enums/TipType";
import removeParamsFromQueryString from "@core/utils/removeParamsFromQueryString";
import removeParamsFromUrlQueryString from "@core/utils/removeParamsFromUrlQueryString";

import MixpanelUtils from "./MixpanelUtils";
import PageNameResolver from "./PageNameResolver";
import TrackingEventName from "./TrackingEventName";
import type TrackingInteractionType from "./TrackingInteractionType";
import TrackingPropertyName from "./TrackingPropertyName";
import type { TrackingEventBaseProperties, TrackingEventOptionalProperties } from "./types";

function isAuthenticated() {
    return bearerFactory.hasToken();
}

function getPropertiesForAllEvents(): TrackingEventBaseProperties {
    const userAgent = navigator.userAgent;

    const browser = MixpanelUtils.browser(userAgent, navigator.vendor, window.opera);
    const browserVersion = MixpanelUtils.browserVersion(userAgent, navigator.vendor, window.opera);
    const os = MixpanelUtils.os(userAgent);
    const device = MixpanelUtils.device(userAgent);

    const { urlWithoutParams: currentUrlWithoutToken } = removeParamsFromUrlQueryString(window.location.href, ["token"]);
    const { urlWithoutParams: documentReferrerWithoutToken } = removeParamsFromUrlQueryString(document.referrer, ["token"]);

    return {
        [TrackingPropertyName.Os]: os,
        [TrackingPropertyName.Browser]: browser,
        [TrackingPropertyName.Referrer]: documentReferrerWithoutToken,
        [TrackingPropertyName.ReferringDomain]: MixpanelUtils.referringDomain(document.referrer),
        [TrackingPropertyName.Device]: device,
        [TrackingPropertyName.CurrentUrl]: currentUrlWithoutToken,
        [TrackingPropertyName.BrowserVersion]: browserVersion,
        [TrackingPropertyName.ScreenHeight]: window.screen.height,
        [TrackingPropertyName.ScreenWidth]: window.screen.width,
        [TrackingPropertyName.AppVersion]: 3,
        [TrackingPropertyName.AppName]: "Activities",
        [TrackingPropertyName.IsMobile]: device.length > 0,
        [TrackingPropertyName.PageName]: PageNameResolver.resolve(window.location.pathname)
    };
}

export function track(eventName: TrackingEventName, properties: TrackingEventOptionalProperties, ignorePageNameValidation = false) {
    if (!isAuthenticated()) {
        // We do not track anonymous users (yet)
        return;
    }

    try {
        const baseProperties = getPropertiesForAllEvents();

        // Whenever an event is about to be sent without PageName, show an error to notify the dev.
        // This will not be shown to the end user.
        if (window.env.SHOW_DETAILED_ERRORS &&
            !ignorePageNameValidation &&
            baseProperties[TrackingPropertyName.PageName] === "Unknown") {
            console.error(`Event '${eventName}' doesn't have a pageName for Url: ${window.location.pathname}`);
        }

        if (window.env.SHOW_DETAILED_ERRORS && console.table) {
            console.groupCollapsed(`Track ${eventName} (${baseProperties[TrackingPropertyName.PageName]})`);
            console.table(properties);
            console.table(baseProperties);
            console.groupEnd();
        }

        return trackAsync(
            eventName,
            {
                ...baseProperties,
                ...properties
            });
    } catch (_) {
        // Do nothing
    }
}

export function trackButtonClicked(isNetworkAdmin: boolean | undefined, isCompanyManager: boolean | undefined, isTeamManager: boolean | undefined, location: string | undefined, trigger: string, otherProperties = {}) {
    return track(TrackingEventName.ButtonClicked, {
        [TrackingPropertyName.IsNetworkAdmin]: isNetworkAdmin,
        [TrackingPropertyName.IsCompanyManager]: isCompanyManager,
        [TrackingPropertyName.IsTeamManager]: isTeamManager,
        [TrackingPropertyName.Location]: location,
        [TrackingPropertyName.Trigger]: trigger,
        ...otherProperties
    });
}

export function trackLinkClicked(isNetworkAdmin: boolean | undefined, isCompanyManager: boolean | undefined, isTeamManager: boolean | undefined, location: string, trigger:string) {
    return track(TrackingEventName.LinkClicked, {
        [TrackingPropertyName.IsNetworkAdmin]: isNetworkAdmin,
        [TrackingPropertyName.IsCompanyManager]: isCompanyManager,
        [TrackingPropertyName.IsTeamManager]: isTeamManager,
        [TrackingPropertyName.Location]: location,
        [TrackingPropertyName.Trigger]: trigger
    });
}

export function trackRedirected(isNetworkAdmin: boolean | undefined, isCompanyManager: boolean | undefined, isTeamManager: boolean | undefined, location:string) {
    return track(TrackingEventName.Redirected, {
        [TrackingPropertyName.IsNetworkAdmin]: isNetworkAdmin,
        [TrackingPropertyName.IsCompanyManager]: isCompanyManager,
        [TrackingPropertyName.IsTeamManager]: isTeamManager,
        [TrackingPropertyName.Location]: location
    });
}

export function trackPageViewed() {
    return track(TrackingEventName.PageViewed, {
        [TrackingPropertyName.QueryParameters]: removeParamsFromQueryString(window.location.search, ["token"])
    });
}

export function trackApplicationStarted(isNetworkAdmin: boolean, isCompanyManager: boolean, isTeamManager: boolean) {
    return track(TrackingEventName.ApplicationStarted, {
        [TrackingPropertyName.IsNetworkAdmin]: isNetworkAdmin,
        [TrackingPropertyName.IsCompanyManager]: isCompanyManager,
        [TrackingPropertyName.IsTeamManager]: isTeamManager
    });
}

export function trackActivityStarted(isNetworkAdmin: boolean | undefined, isCompanyManager: boolean | undefined, isTeamManager: boolean | undefined, activityType: ActivityType, isFirstActivity: boolean | undefined) {
    return track(TrackingEventName.ActivityStarted, {
        [TrackingPropertyName.IsNetworkAdmin]: isNetworkAdmin,
        [TrackingPropertyName.IsCompanyManager]: isCompanyManager,
        [TrackingPropertyName.IsTeamManager]: isTeamManager,
        [TrackingPropertyName.ActivityType]: activityType,
        [TrackingPropertyName.IsFirstActivity]: isFirstActivity
    });
}

export function trackActivityCompleted(isNetworkAdmin: boolean | undefined, isCompanyManager: boolean | undefined, isTeamManager: boolean | undefined, activityType: ActivityType | undefined, isFirstActivity: boolean | undefined) {
    return track(TrackingEventName.ActivityCompleted, {
        [TrackingPropertyName.IsNetworkAdmin]: isNetworkAdmin,
        [TrackingPropertyName.IsCompanyManager]: isCompanyManager,
        [TrackingPropertyName.IsTeamManager]: isTeamManager,
        [TrackingPropertyName.ActivityType]: activityType,
        [TrackingPropertyName.IsFirstActivity]: isFirstActivity
    });
}

export function trackFeedbackShown(
    isNetworkAdmin: boolean | undefined,
    isCompanyManager: boolean | undefined,
    isTeamManager: boolean | undefined,
    activityType: ActivityType | undefined,
    questionId: string | undefined,
    subMetricId: string | undefined = undefined,
    isCustomQuestion: boolean = false,
    questionLanguage: string | undefined = undefined) {
    return track(TrackingEventName.FeedbackShown, {
        [TrackingPropertyName.IsNetworkAdmin]: isNetworkAdmin,
        [TrackingPropertyName.IsCompanyManager]: isCompanyManager,
        [TrackingPropertyName.IsTeamManager]: isTeamManager,
        [TrackingPropertyName.ActivityType]: activityType,
        [TrackingPropertyName.QuestionId]: questionId,
        [TrackingPropertyName.SubMetricId]: subMetricId,
        [TrackingPropertyName.IsCustomQuestion]: isCustomQuestion,
        [TrackingPropertyName.QuestionLanguage]: questionLanguage
    });
}

export function trackFeedbackGiven(
    isNetworkAdmin: boolean | undefined,
    isCompanyManager: boolean | undefined,
    isTeamManager: boolean | undefined,
    activityType: ActivityType,
    interactionType: TrackingInteractionType,
    isAnonymous: boolean | undefined,
    questionId: string | undefined,
    subMetricId?: string,
    isCustomQuestion: boolean = false
) {
    return track(TrackingEventName.FeedbackGiven, {
        [TrackingPropertyName.IsNetworkAdmin]: isNetworkAdmin,
        [TrackingPropertyName.IsCompanyManager]: isCompanyManager,
        [TrackingPropertyName.IsTeamManager]: isTeamManager,
        [TrackingPropertyName.ActivityType]: activityType,
        [TrackingPropertyName.InteractionType]: interactionType,
        [TrackingPropertyName.IsAnonymous]: isAnonymous,
        [TrackingPropertyName.QuestionId]: questionId,
        [TrackingPropertyName.SubMetricId]: subMetricId,
        [TrackingPropertyName.IsCustomQuestion]: isCustomQuestion
    });
}

export function trackQuestionAnswered(isNetworkAdmin: boolean | undefined, isCompanyManager: boolean | undefined, isTeamManager: boolean | undefined, activityType: ActivityType, interactionType: TrackingInteractionType, questionId: string | undefined | null) {
    return track(TrackingEventName.QuestionAnswered, {
        [TrackingPropertyName.IsNetworkAdmin]: isNetworkAdmin,
        [TrackingPropertyName.IsCompanyManager]: isCompanyManager,
        [TrackingPropertyName.IsTeamManager]: isTeamManager,
        [TrackingPropertyName.ActivityType]: activityType,
        [TrackingPropertyName.InteractionType]: interactionType,
        [TrackingPropertyName.QuestionId]: questionId
    });
}

export function trackSurveyTipShown(type: TipType) {
    return track(TrackingEventName.SurveyTipShown, {
        [TrackingPropertyName.Type]: type
    });
}

export function trackSurveyTipDismissed(type: TipType) {
    return track(TrackingEventName.SurveyTipDismissed, {
        [TrackingPropertyName.Type]: type
    });
}

export function trackLogRocket(sessionUrl: string) {
    return track(TrackingEventName.LogRocket, {
        [TrackingPropertyName.SessionUrl]: sessionUrl
    });
}