enum TrackingPropertyName {
    // Base properties
    AppVersion = "App Version",
    AppName = "App Name",
    Browser = "$browser",
    BrowserVersion = "$browser_version",
    CurrentUrl = "$current_url",
    Device = "$device",
    IsMobile = "Is Mobile",
    Os = "$os",
    PageName = "Page Name",
    Referrer = "$referrer",
    ReferringDomain = "$referring_domain",
    ScreenHeight = "$screen_height",
    ScreenWidth = "$screen_width",

    // Other properties
    ActivityType = "Activity Type",
    CategoryId = "Category Id",
    CorrelationId = "Correlation Id",
    InteractionType = "Interaction Type",
    IsAnonymous = "Is Anonymous",
    IsCompanyManager = "Is Company Manager",
    IsFirstActivity = "Is First Activity",
    IsTeamManager = "Is Team Manager",
    IsCustomQuestion = "Is Custom Question",
    IsNetworkAdmin = "Is Admin",
    Location = "Location",
    NextSlide = "Next Slide",
    PreviousSlide = "Previous Slide",
    PromptId = "Prompt Id",
    QueryParameters = "Query Parameters",
    QuestionId = "Question Id",
    QuestionLanguage = "Question Language",
    SessionUrl = "sessionURL",
    SubMetricId = "Sub Metric Id",
    Trigger = "Trigger",
    Type = "Type"
}

export default TrackingPropertyName;
