import React, { useState } from "react";

import TagPicker, { type TagItem } from "@igloo-ui/tag-picker";

import { getResizedImageUrl } from "@hooks/useImageResizeUrl";

import ImageSizes from "@components/user-image/ImageSizes";

import type { UserIdentity } from "@api/RecognitionActivityApiClient";

export interface RecognitionRecipientPickerProps {
    className?: string;
    selectedRecipients: TagItem[];
    searchPeersAsync: (searchTerm: string, excludedIds: string[]) => Promise<UserIdentity[]>;
    onRecipientsAdded: (recipient: TagItem) => void;
    onRecipientsRemoved: (recipientId: string) => void;
    placeholder: string;
    noResultsText: string;
    maxRecipients: number;
    displayError: boolean;
}

const RecognitionRecipientsTagPicker = (props :RecognitionRecipientPickerProps) => {
    const { className, selectedRecipients, searchPeersAsync, onRecipientsAdded, onRecipientsRemoved, placeholder, noResultsText, maxRecipients, displayError } = props;

    const [isLoadingSearch, setLoadingSearch] = useState(false);
    const [recipients, setRecipients] = useState<TagItem[]>([]);

    const handleOnRecipientsSearchAsync = async(searchTerm: string) => {
        setLoadingSearch(true);
        const excludedIds = selectedRecipients.map(x => x.id);
        const data : UserIdentity[] = await searchPeersAsync(searchTerm, excludedIds);
        const membersGroupedByName = Object.groupBy(data, ({ fullName }) => fullName);

        const result = data.map<TagItem>(x => ({
            id: x.userId,
            text: x.fullName,
            subtext: membersGroupedByName[x.fullName]!.length > 1 || selectedRecipients.some(r => r.text === x.fullName) ? x.email : undefined,
            src: getResizedImageUrl(x.imageUrl, ImageSizes.Small) ?? undefined
        }));

        setRecipients(result);
        setLoadingSearch(false);
    };

    const handleOnAddRecipient = (userId: string) => {
        const recipient = recipients.find(x => x.id === userId);

        if (recipient) {
            onRecipientsAdded(recipient);
        }
    };

    const handleOnRemoveRecipient = (userId: string) => {
        onRecipientsRemoved(userId);
    };

    return <TagPicker className={className}
        placeholder={placeholder}
        onInput={handleOnRecipientsSearchAsync}
        loading={isLoadingSearch}
        results={recipients}
        selectedResults={selectedRecipients}
        showSearchIcon
        onSelection={handleOnAddRecipient}
        onTagRemove={handleOnRemoveRecipient}
        noResultsText={noResultsText}
        maxTags={maxRecipients}
        error={displayError}
    />;
};

export default RecognitionRecipientsTagPicker;